import type { AmplitudeClient } from "amplitude-js";
import { createContext, useContext, useMemo } from "react";

interface IAmplitudeContextType {
  amplitudeInstance?: AmplitudeClient;
  getParentContext?(): IAmplitudeContextType;
  eventProperties?: any;
}

export const AmplitudeContext = createContext<IAmplitudeContextType>({
  eventProperties: {},
});

function isValidAmplitudeInstance(maybeInstance: any) {
  return !!maybeInstance && typeof maybeInstance.init === "function" && typeof maybeInstance.logEvent === "function";
}

export function useAmplitudeContext() {
  return useContext(AmplitudeContext);
}

function initializeAmplitude(apiKey: string, userId: any, amplitudeInstance: AmplitudeClient) {
  return () => {
    if (isValidAmplitudeInstance(amplitudeInstance)) {
      if (apiKey) {
        amplitudeInstance.init(apiKey, undefined, {
          includeGclid: true,
          includeReferrer: true,
          includeUtm: true,
          forceHttps: true,
          unsetParamsReferrerOnNewSession: false,
          // If you set `saveParamsReferrerOncePerSession` to false in your JavaScript SDK configuration, the SDK will
          // always capture any new values from the user. This will update the following user properties throughout a
          // session if they change: utm_source, utm_medium, utm_campaign, utm_campaign, utm_term, utm_content,
          // referrer, referring_domain, gclid and fbclid
          saveParamsReferrerOncePerSession: true,
          // @ts-ignore
          logAttributionCapturedEvent: true,
        });
      }

      if (userId) {
        amplitudeInstance.setUserId(userId);
      }
    }
  };
}

interface IAmplitudeProviderProps {
  amplitudeInstance: AmplitudeClient;
  apiKey: string;
  userId?: string;
  children: any;
}

export function AmplitudeProvider({ apiKey, userId, amplitudeInstance, children }: IAmplitudeProviderProps) {
  // memoize so it's only really called if the params change
  const initialize = useMemo(
    () => initializeAmplitude(apiKey, userId, amplitudeInstance),
    [apiKey, userId, amplitudeInstance],
  );

  initialize();

  return (
    <AmplitudeContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        amplitudeInstance: apiKey ? amplitudeInstance : undefined,
        eventProperties: {},
      }}
    >
      {children}
    </AmplitudeContext.Provider>
  );
}
