/* eslint-disable react/jsx-curly-brace-presence */
import getUnixTime from "date-fns/getUnixTime";
import parseISO from "date-fns/parseISO";
import { getUtmParametersFromParsedUrlQuery } from "lib/analytics/utm";
import useSelfie from "lib/hooks/useSelfie";

import stripUndefined from "lib/utils/stripUndefined";
import { useRouter } from "next/router";
import { type ReactElement } from "react";
import { useIntl } from "react-intl";
import type { IntercomProps } from "react-use-intercom";
import { IntercomProvider as Provider } from "react-use-intercom";

interface IntercomProviderProps {
  children: ReactElement;
}
export default function IntercomProvider({ children }: IntercomProviderProps) {
  const intl = useIntl();
  const selfie = useSelfie();
  const router = useRouter();
  if (!selfie?.intercom) {
    return children;
  }

  const utmParameters = getUtmParametersFromParsedUrlQuery(router.query);
  const autoBootProps: IntercomProps = {
    userHash: selfie.intercom?.verificationHash,
    userId: selfie._id,

    name: selfie.fullName,
    email: selfie.email,
    languageOverride: intl.locale.substring(0, 2),
    avatar: selfie.pictureUrl
      ? {
          type: "avatar",
          imageUrl: selfie.pictureUrl,
        }
      : undefined,
    ...utmParameters,
    createdAt: selfie.signedUpAt ? getUnixTime(parseISO(selfie.signedUpAt)) : undefined,
    customAttributes: {
      // eslint-disable-next-line no-nested-ternary
      plan: selfie.isBlack ? "BLACK" : selfie.isPremium ? "PREMIUM" : "FREE",
      // Flags
      isSiteAdmin: selfie.isSiteAdmin || false,
      isPremum: selfie.isPremium,
      isBlack: selfie.isBlack,
      isFree: !selfie.isPremium && !selfie.isBlack,
    },
    hideDefaultLauncher: true,
  };

  return (
    <Provider
      autoBoot={true}
      shouldInitialize={true}
      appId={selfie.intercom?.appId || ""}
      autoBootProps={stripUndefined(autoBootProps)}
    >
      {children}
    </Provider>
  );
}
