import localFont from "next/font/local";

const aeonik = localFont({
  variable: "--font-aeonik",
  display: "swap",
  fallback: ["system-ui"],
  src: [
    { path: "../../public/fonts/aeonik/Aeonik-Thin.woff2", weight: "100", style: "normal" },
    { path: "../../public/fonts/aeonik/Aeonik-ThinItalic.woff2", weight: "100", style: "italic" },

    { path: "../../public/fonts/aeonik/Aeonik-Light.woff2", weight: "200", style: "normal" },
    { path: "../../public/fonts/aeonik/Aeonik-LightItalic.woff2", weight: "200", style: "italic" },

    { path: "../../public/fonts/aeonik/Aeonik-Regular.woff2", weight: "300", style: "normal" },
    { path: "../../public/fonts/aeonik/Aeonik-RegularItalic.woff2", weight: "300", style: "italic" },

    { path: "../../public/fonts/aeonik/Aeonik-Regular.woff2", weight: "400", style: "normal" },
    { path: "../../public/fonts/aeonik/Aeonik-RegularItalic.woff2", weight: "400", style: "italic" },

    { path: "../../public/fonts/aeonik/Aeonik-Medium.woff2", weight: "500", style: "normal" },
    { path: "../../public/fonts/aeonik/Aeonik-MediumItalic.woff2", weight: "500", style: "italic" },

    { path: "../../public/fonts/aeonik/Aeonik-Bold.woff2", weight: "800", style: "normal" },
    { path: "../../public/fonts/aeonik/Aeonik-BoldItalic.woff2", weight: "800", style: "italic" },

    { path: "../../public/fonts/aeonik/Aeonik-Black.woff2", weight: "900", style: "normal" },
    { path: "../../public/fonts/aeonik/Aeonik-BlackItalic.woff2", weight: "900", style: "italic" },
  ],
});

export default aeonik;
