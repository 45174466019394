import type { ISelfieQuery } from "lib/queries/__generated__/SelfieQuery.generated";
import { useContext } from "react";
import type { IAuthContext } from "../../components/auth/Auth";
import { AuthContext } from "../../components/auth/Auth";

export default function useSelfie() {
  const { user } = useContext<IAuthContext>(AuthContext);
  return user;
}

export type Viewer = NonNullable<ISelfieQuery["selfie"]>;
export type Selfie = ISelfieQuery["selfie"];
