/* eslint-disable no-param-reassign */
import { ApolloLink } from "@apollo/client";
import type { Operation } from "@apollo/client/core";
import { createHttpLink } from "@apollo/client/core";
import { setContext } from "@apollo/client/link/context";
// import { queryHashLink } from "lib/apollo/queryHashLink";
import { invariant } from "outvariant";

const isServer = typeof window === "undefined";

function queryDefinesMutation(query: Operation["query"]) {
  return query.definitions.some((definition) => {
    return definition.kind === "OperationDefinition" && definition.operation === "mutation";
  });
}

export default function createFundamenteiHttpLink() {
  invariant(process.env.API_URL, "Missing `API_URL` environment variable");

  // if (process.env.NODE_ENV === "production") {
  //   return createHttpLink({
  //     useGETForQueries: false,
  //     uri: process.env.API_URL,
  //   });
  // }

  return ApolloLink.from([
    // queryHashLink,
    setContext(async (request, currentContext) => {
      if (queryDefinesMutation(request.query)) {
        return currentContext;
      }
      return currentContext;
    }),
    createHttpLink({
      useGETForQueries: false,
      uri: (operation) => {
        // Don't need to modify anything on the server
        if (isServer) {
          return process.env.API_URL || "";
        }

        // Allow browser to go via Tailscale
        if (typeof window !== "undefined" && window.location.hostname.startsWith("100.")) {
          const originalApiUrl = new URL(process.env.API_URL || "");
          return `http://${window.location.hostname}:${originalApiUrl.port}`;
        }

        // Better DX for debugging
        const url = new URL(process.env.API_URL || "");

        // Query hash
        // if (operation.extensions.queryHash) {
        //   url.searchParams.set("queryHash", operation.extensions.queryHash);
        // }

        // Variables hash
        // if (operation.extensions.variablesHash) {
        //   url.searchParams.set("variablesHash", operation.extensions.variablesHash);
        // }

        const payload = getJwtPayload(operation.getContext().headers);
        const isStaff = payload?.user.email.includes("@fundamentei.com") || false;

        if (operation.operationName && (isStaff || process.env.NODE_ENV === "development")) {
          // Binds the operation name to the request
          url.searchParams.set("operationName", operation.operationName);
        }

        return url.toString();
      },
    }),
  ]);
}

function getJwtPayload(headers: Record<string, any>) {
  const authorization: string = headers.Authorization || "";
  if (!authorization) {
    return null;
  }
  const matches = authorization.match(/Bearer (.+)/i);
  if (matches && matches.length >= 1) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_headers, payload] = matches[1].split(".");
    // Decode the payload

    try {
      return JSON.parse(atob(payload));
    } catch (error) {
      return null;
    }
  }
  return null;
}
