/* eslint-disable react/static-property-placement */
import type http from "http";
import type { NextPageContext } from "next";
import type { AppContext, AppProps, default as NextApp } from "next/app";
import { Component } from "react";
import ServerResponseContext from "./ServerResponseContext";

interface IWithServerResponseContext extends AppContext {
  ctx: NextPageContext;
}

interface IWithServerResponseProps {
  response: () => http.ServerResponse;
}

export default function withServerResponse(App: typeof NextApp) {
  return class WithServerResponse extends Component<IWithServerResponseProps & AppProps> {
    static defaultProps = {
      response: () => null,
    };

    static async getInitialProps(context: IWithServerResponseContext) {
      const { ctx } = context;
      let props = {};

      if (App.getInitialProps) {
        props = await App.getInitialProps(context);
      }

      return {
        ...props,
        response: () => ctx.res,
      };
    }

    render() {
      const { response, ...props } = this.props;

      if (typeof window !== "undefined") {
        return <App {...props} />;
      }

      return (
        <ServerResponseContext.Provider value={response()}>
          <App {...props} />
        </ServerResponseContext.Provider>
      );
    }
  };
}
