/* eslint-disable */

export interface PossibleTypesResultData {
  possibleTypes: {
    [key: string]: string[];
  };
}
const result: PossibleTypesResultData = {
  possibleTypes: {
    AgendaEvent: [
      "EarningsResultsEvent",
      "LabeledEvent",
      "PaymentOfDividendsEvent",
      "PaymentOfStockDividendsEvent",
      "RightsIssueEvent",
      "SpinOffEvent",
      "StandardEvent",
      "StockSplitEvent",
    ],
    AnswerablePost: ["PostReply"],
    Asset: ["AmericanCompanyIssuer", "BrazilianCompanyIssuer", "BrazilianRealEstateFund"],
    BrazilianBankFinancialStatementRow: [
      "QuarterlyBrazilianBankFinancialStatementRow",
      "YearlyBrazilianBankFinancialStatementRow",
    ],
    BrazilianCompanyFinancialStatementRow: [
      "QuarterlyBrazilianCompanyFinancialStatementRow",
      "YearlyBrazilianCompanyFinancialStatementRow",
    ],
    BrazilianCompanyIssuerSharesTotal: [
      "BrazilianCompanyIssuerFreeFloat",
      "BrazilianCompanyIssuerShareholderPosition",
      "BrazilianCompanyIssuerSharesOutstanding",
      "BrazilianCompanyIssuerTreasuryShares",
    ],
    BrazilianInsurerFinancialStatementRow: [
      "QuarterlyBrazilianInsurerFinancialStatementRow",
      "YearlyBrazilianInsurerFinancialStatementRow",
    ],
    BrazilianRealEstateFundFinancialStatementRow: [],
    BrazilianRealEstateFundProperty: [
      "BrazilianRealEstateFundPropertyToRent",
      "BrazilianRealEstateFundPropertyToRentUnderConstruction",
      "BrazilianRealEstateFundPropertyToSale",
      "BrazilianRealEstateFundPropertyToSaleUnderConstruction",
    ],
    BrazilianRealEstateFundRealProperty: [
      "BrazilianRealEstateFundLand",
      "BrazilianRealEstateFundPropertyToRent",
      "BrazilianRealEstateFundPropertyToRentUnderConstruction",
      "BrazilianRealEstateFundPropertyToSale",
      "BrazilianRealEstateFundPropertyToSaleUnderConstruction",
    ],
    Comment: ["AssetDiscussionComment", "AssetDiscussionCommentReply"],
    EditableComment: ["AssetDiscussionComment", "AssetDiscussionCommentReply"],
    EditablePost: ["CommonPost", "ForumPost", "PostReply"],
    FinancialIndicatorSeriesNode: [
      "FinancialIndicatorSeriesCurrencyNode",
      "FinancialIndicatorSeriesFinancialNode",
      "FinancialIndicatorSeriesMetricNode",
      "FinancialIndicatorSeriesNumberNode",
      "FinancialIndicatorSeriesPercentNode",
      "FinancialIndicatorSeriesRatioNode",
      "FinancialIndicatorSeriesSkeletonNode",
      "FinancialIndicatorSeriesTextNode",
    ],
    FinancialSegment: ["FinancialBusinessSegment", "FinancialGeographicSegment"],
    FinancialSegmentIndicator: ["FinancialSegmentFinancialValueIndicator", "FinancialSegmentRatioIndicator"],
    FinancialsSheetContextItem: ["FinancialsSheetFNETContextItem", "FinancialsSheetSECContextItem"],
    FundamenteiMedia: ["CloudflareBlackVideo"],
    IndicatorDefinition: ["FinancialsComparisonSheetIndicator", "FinancialsSheetIndicator", "Indicator"],
    Issuer: [
      "AmericanCompanyIssuer",
      "BrazilianCompanyIssuer",
      "BrazilianRealEstateFund",
      "BrazilianRealEstateFundIssuer",
    ],
    IssuerLogos: [
      "AmericanCompanyIssuer",
      "BrazilianCompanyIssuer",
      "BrazilianRealEstateFund",
      "BrazilianRealEstateFundIssuer",
    ],
    IssuerSearchResultNode: ["AmericanIssuerSearchResultNode"],
    Liquidity: ["AmericanCompanyLiquidity", "BrazilianCompanyLiquidity", "BrazilianRealEstateFundLiquidity"],
    LockableComment: ["AssetDiscussionComment"],
    LockablePost: ["ForumPost"],
    Node: ["CompanyIssuer", "FinancialsTemplate", "Indicator"],
    PinnableComment: ["AssetDiscussionComment"],
    PinnablePost: ["ForumPost", "PostReply"],
    PortfolioAsset: [
      "AmericanCompanyPortfolioAsset",
      "AmericanReitPortfolioAsset",
      "BrazilianCompanyPortfolioAsset",
      "BrazilianRealEstateFundPortfolioAsset",
    ],
    Post: ["CommonPost", "ForumPost"],
    QuarterlyBrazilianCompanyFinancials: [
      "QuarterlyBrazilianBankFinancialStatementRow",
      "QuarterlyBrazilianCompanyFinancialStatementRow",
      "QuarterlyBrazilianInsurerFinancialStatementRow",
    ],
    RankedIssuer: [
      "AmericanCompanyIssuer",
      "BrazilianCompanyIssuer",
      "BrazilianRealEstateFund",
      "BrazilianRealEstateFundIssuer",
    ],
    Reactable: ["AssetDiscussionComment", "AssetDiscussionCommentReply", "CommonPost", "ForumPost", "PostReply"],
    ReportSource: ["CvmSubmission", "SecSubmission"],
    Security: ["AmericanSecurity", "BrazilianSecurity"],
    SpeculativeContent: [
      "HistoricalFFOVersusQuote",
      "HistoricalNetIncomeVersusQuote",
      "HistoricalReturn",
      "HistoricalShareholders",
      "HistoricalTradingSessions",
    ],
    Starrable: ["FinancialsTemplate"],
    StripeCoupon: ["StripeFixedAmountCoupon", "StripePercentCoupon"],
    StripeObject: ["StripeCheckoutSession", "StripeInvoice", "StripePaymentIntent", "StripeSubscription"],
    TotalCompensationByYear: ["AmericanCompanyTotalCompensationByYear", "AmericanREITTotalCompensationByYear"],
    UnfurlOEmbed: ["UnfurlOEmbedLink", "UnfurlOEmbedPhoto", "UnfurlOEmbedRich", "UnfurlOEmbedVideo"],
    UniformResourceLocatable: ["AmericanIssuerSearchResultNode", "AssetDiscussionComment", "CommonPost", "ForumPost"],
    YearlyBrazilianCompanyFinancials: [
      "YearlyBrazilianBankFinancialStatementRow",
      "YearlyBrazilianCompanyFinancialStatementRow",
      "YearlyBrazilianInsurerFinancialStatementRow",
    ],
  },
};
export default result;
