/* eslint-disable react-hooks/exhaustive-deps */
import { useAmplitude } from "lib/amplitude/Amplitude";
import { getUtmParametersFromParsedUrlQuery } from "lib/analytics/utm";
import stripUndefined from "lib/utils/stripUndefined";
import { Router, useRouter } from "next/router";
import type { ReactElement } from "react";
import { memo, useCallback, useEffect } from "react";
import { useEffectOnce } from "react-use";

interface AmplitudeTrackPageViewsProps {
  children?: ReactElement;
}

function AmplitudeTrackPageViews({ children }: AmplitudeTrackPageViewsProps) {
  const amplitude = useAmplitude();
  const router = useRouter();

  useEffectOnce(() => {
    amplitude.logEvent("Viewed page", {
      route: router.route || null,
      pathname: router.pathname || null,
      referrer: typeof document !== "undefined" ? document.referrer : null,
      ...getUtmParametersFromParsedUrlQuery(router.query),
    });
  });

  const routeChangeComplete = useCallback(() => {
    const eventProperties = stripUndefined({
      route: router.route || null,
      pathname: router.pathname || null,
      search: router.asPath.replace(router.pathname, "") || null,
      referrer: typeof document !== "undefined" ? document.referrer : null,
      ...getUtmParametersFromParsedUrlQuery(router.query),
    });

    const isEmptyEvent = Object.keys(eventProperties).every((key) => {
      return eventProperties[key] === null;
    });

    if (!isEmptyEvent) {
      amplitude.logEvent("Viewed page", eventProperties);
    }
  }, [router.route, router.pathname, router.asPath]);

  useEffect(() => {
    Router.events.on("routeChangeComplete", routeChangeComplete);
    return () => router.events.off("routeChangeComplete", routeChangeComplete);
  }, [routeChangeComplete, router.events]);

  return children || null;
}

export default memo(AmplitudeTrackPageViews);
