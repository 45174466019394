import { decode, encode } from "@msgpack/msgpack";
import { arrayBufferToBase64, base64ToArrayBuffer } from "lib/utils/arrayBuffer";
import type { ParsedUrlQuery } from "querystring";
import type { IAssetType } from "types/graphqlTypes";

export function getInitialSearchStateFromQuery(query: ParsedUrlQuery) {
  if (query.searchState && typeof query.searchState === "string") {
    try {
      return decodeSearchState(query.searchState);
    } catch (e) {
      return {};
    }
  }

  return {};
}

export function encodeSearchState(rawSearchState: Record<string, unknown>) {
  return arrayBufferToBase64(encode(rawSearchState));
}

export function decodeSearchState(encodedSearchState: string) {
  return decode(base64ToArrayBuffer(encodedSearchState)) as Record<string, unknown>;
}

export function withSelectedIndustrySector(assetType: IAssetType, industrySector: string) {
  return {
    menu: {
      type: assetType,
    },
    page: 1,
    configure: {
      maxValuesPerFacet: 20,
      hitsPerPage: 24,
    },
    refinementList: {
      industrySector: [industrySector],
    },
  };
}
