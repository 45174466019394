import gql from "graphql-tag";
const UserSettingsV2Fields = gql`
  fragment UserSettingsV2Fields on UserSettingsV2 {
    enabledIssuerPageBoxes {
      acoes
      fiis
      stocks
    }
    financialsTable {
      defaultTemplates {
        objectId
        templateCodes
        vertical
      }
      fullBleed
      sortDirection
      textAlignment
    }
    hasClosedMarch24PerpetualBlackPopUp
    id
    language
    objectId
    ownerId
    theme
  }
`;
export default UserSettingsV2Fields;
