import debounce from "lodash/fp/debounce";
import { useMemo } from "react";
import * as React from "react";
import { AmplitudeContext, useAmplitudeContext } from "./AmplitudeProvider";

export type TAmplitude = {
  logEvent: (eventType: string, eventPropertiesIn?: Record<string, any>, callback?: any) => void;
  setUserId: (userId: string | null) => void;
  setUserProperties: (userProperties: Record<string, any>) => void;
  clearUserProperties: () => void;
  resetSessionId: () => void;
  regenerateDeviceId: () => void;
  eventProperties: Record<string, any>;
  amplitudeInstance: any;
};

export function useDebouncedAmplitude(
  debounceIntervalMs = 600,
  eventProperties: Record<string, any> = {},
  instanceName = "$default_instance",
): TAmplitude {
  const amplitude = useAmplitude(eventProperties, instanceName);

  return {
    ...amplitude,
    logEvent: debounce(debounceIntervalMs, amplitude.logEvent),
  };
}

export function useAmplitude(
  eventProperties: Record<string, any> = {},
  instanceName = "$default_instance",
): TAmplitude {
  const { amplitudeInstance, eventProperties: inheritedProperties } = useAmplitudeContext();

  return useMemo(() => {
    // eslint-disable-next-line default-param-last
    function logEvent(eventType: string, eventPropertiesIn: Record<string, any> = {}, callback?: any) {
      if (!amplitudeInstance) {
        return;
      }

      if (typeof amplitudeInstance.logEvent !== "function") {
        return;
      }

      let computed = inheritedProperties;

      if (typeof eventProperties === "function") {
        computed = eventProperties(computed);
      } else {
        computed = {
          ...computed,
          ...(eventProperties || {}),
        };
      }

      if (typeof eventPropertiesIn === "function") {
        computed = eventPropertiesIn(computed);
      } else {
        computed = {
          ...computed,
          ...(eventPropertiesIn || {}),
        };
      }

      amplitudeInstance.logEvent(eventType, computed, callback);
    }

    function instrument<T extends CallableFunction>(eventType: string, callable: T): T {
      function fn(...params: any) {
        const returnValue = callable ? callable(...params) : undefined;
        logEvent(eventType);
        return returnValue;
      }

      return fn as any;
    }

    function setUserId(userId: string | null) {
      if (amplitudeInstance && typeof amplitudeInstance.setUserId === "function") {
        amplitudeInstance.setUserId(userId);
      }
    }

    function setUserProperties(userProperties: Record<string, any>) {
      if (amplitudeInstance && typeof amplitudeInstance.setUserProperties === "function") {
        amplitudeInstance.setUserProperties(userProperties);
      }
    }

    function clearUserProperties() {
      if (amplitudeInstance && typeof amplitudeInstance.clearUserProperties === "function") {
        amplitudeInstance.clearUserProperties();
      }
    }

    function resetSessionId() {
      if (amplitudeInstance && typeof (amplitudeInstance as any).resetSessionId === "function") {
        (amplitudeInstance as any).resetSessionId();
      }
    }

    function regenerateDeviceId() {
      if (amplitudeInstance && typeof (amplitudeInstance as any).regenerateDeviceId === "function") {
        (amplitudeInstance as any).regenerateDeviceId();
      }
    }

    return {
      logEvent,
      instrument,
      eventProperties: inheritedProperties,
      setUserId,
      setUserProperties,
      clearUserProperties,
      resetSessionId,
      regenerateDeviceId,
      amplitudeInstance,
    };
  }, [eventProperties, amplitudeInstance, inheritedProperties, instanceName]);
}

interface IAmplitudeProps {
  children:
    | React.ReactNode
    | ((props: {
        logEvent: (eventType: string, eventPropertiesIn?: Record<string, any>, callback?: any) => void;
      }) => React.ReactNode);
  eventProperties?: Record<string, any>;
  instanceName?: string;
  userProperties?: Record<string, any>;
}

export function Amplitude(props: IAmplitudeProps) {
  const { children, instanceName, userProperties } = props;
  const { logEvent, eventProperties, amplitudeInstance } = useAmplitude(undefined, instanceName);

  useMemo(() => {
    return () => {
      if (userProperties && amplitudeInstance && typeof amplitudeInstance.setUserProperties === "function") {
        amplitudeInstance.setUserProperties(userProperties);
      }
    };
  }, [userProperties, amplitudeInstance])();

  return (
    <AmplitudeContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        eventProperties: {
          ...eventProperties,
          // eslint-disable-next-line react/destructuring-assignment
          ...(props.eventProperties || {}),
        },
        amplitudeInstance,
      }}
    >
      {typeof children === "function" ? children({ logEvent }) : children || null}
    </AmplitudeContext.Provider>
  );
}
