import noop from "lodash/fp/noop";
import { createContext, useState } from "react";

interface ISearchStateContextValue {
  searchState: any;
  setSearchState: (searchState: any) => void;
}

const SearchStateContext = createContext<ISearchStateContextValue>({
  searchState: {},
  setSearchState: noop,
});

interface ISearchStateProviderProps {
  initialSearchState?: Record<string, unknown>;
  children: any;
}

export function SearchStateProvider({ initialSearchState, children }: ISearchStateProviderProps) {
  const [searchState, setSearchState] = useState(initialSearchState);

  return (
    <SearchStateContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        searchState,
        setSearchState,
      }}
    >
      {children}
    </SearchStateContext.Provider>
  );
}

export const SearchStateConsumer = SearchStateContext.Consumer;

export default SearchStateContext;
