// Copyright (C) Fundamentei Serviços de Informação LTDA - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited. Proprietary and confidential

import { invariant } from "outvariant";
import jsesc from "jsesc";
import crc32 from "crc/crc32";

export function encode<Data>(data: Data, seed = BAD_INT_SEED): [string, number] {
  invariant(
    seed.every((n) => Number.isInteger(n) && n >= 0 && n <= 255),
    "Seed must be an array of integers between 0 and 255",
  );
  invariant(seed.length >= 32, "Seed must be an array of at least 32 integers");

  // We need to handle UTF-8 characters in a way that it doesn't mess with the right order of bytes. In other words:
  // "você" should be encoded correctly, that's why it's better to escape the string before encoding it since we can't
  // safely handle those characters.
  // See: https://github.com/mathiasbynens/jsesc#json
  const stringified = jsesc(data, {
    numbers: "decimal",
    compact: true,
    json: true,
  });
  const encoder = new TextEncoder();
  const bytes = encoder.encode(stringified);

  try {
    for (let i = 0, k = seed.length; i < bytes.length; i += 1) {
      const a = seed[i % k] % bytes.length;
      const b = i;

      [bytes[a], bytes[b]] = [bytes[b], bytes[a]];
    }
  } catch (err) {
    return ["", -1];
  }

  const decoder = new TextDecoder();
  return [decoder.decode(bytes), crc32(stringified)];
}

export function decode<Data>(encoded: string, seed = BAD_INT_SEED): [Data | null, number] {
  invariant(typeof encoded === "string" && encoded !== "", "The encoded message must be a string and not be empty");
  invariant(
    seed.every((n) => Number.isInteger(n) && n >= 0 && n <= 255),
    "Seed must be an array of integers between 0 and 255",
  );
  invariant(seed.length >= 32, "Seed must be an array of at least 32 integers");

  const encoder = new TextEncoder();
  const bytes = encoder.encode(encoded);

  try {
    for (let i = bytes.length - 1, k = seed.length; i >= 0; i -= 1) {
      const a = seed[i % k] % bytes.length;
      const b = i;

      [bytes[a], bytes[b]] = [bytes[b], bytes[a]];
    }
  } catch (err) {
    throw new Error("Couldn't process the encoded message");
  }

  const decoder = new TextDecoder();
  const decoded = decoder.decode(bytes);
  return [JSON.parse(decoded) as unknown as Data, crc32(decoded)];
}

const BAD_INT_SEED = [
  91, 129, 55, 54, 56, 5, 156, 61, 159, 116, 100, 167, 108, 63, 159, 176, 151, 56, 36, 66, 113, 59, 31, 78, 144, 161,
  135, 163, 72, 98, 194, 38, 11, 123, 15, 149, 182, 48, 58, 108, 27, 62, 50, 186, 15, 167, 86, 158, 166, 80, 90, 157, 6,
  156, 171, 184, 189, 34, 88, 49, 156, 38, 146, 119, 170, 144, 40, 183, 199, 111, 69, 172, 26, 41, 153, 117, 187, 170,
  94, 161, 60, 140, 157, 2, 10, 68, 105, 163, 89, 41, 177, 43, 40, 90, 72, 136, 135, 182, 29, 132,
];
