/* eslint-disable react/static-property-placement */
import { decode, encode } from "lib/internal/enigma/enigma";
import type { NextPageContext } from "next";
import type { AppContext, AppProps, default as NextApp } from "next/app";
import { Component } from "react";

interface WithEnigmaDecoderContext extends AppContext {
  ctx: NextPageContext;
}

export default function withEnigmaDecoder(App: typeof NextApp) {
  return class WithServerResponse extends Component<AppProps> {
    static async getInitialProps(context: WithEnigmaDecoderContext) {
      let props: Record<string, any> = {};

      if (App.getInitialProps) {
        props = await App.getInitialProps(context);
        if (props.apolloState && props.apolloState.data) {
          const [encoded, encodedIntegrity] = encode(props.apolloState.data);
          const [decoded, decodedIntegrity] = decode(encoded);
          if (decoded && decodedIntegrity === encodedIntegrity) {
            props.apolloState.data = `)]}'${encoded}`;
          }
        }
      }

      return {
        ...props,
      };
    }

    render() {
      return <App {...this.props} />;
    }
  };
}
