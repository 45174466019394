export default function getBaseUrl() {
  if (process.env.FUNDAMENTEI_ENV === "development") {
    if (typeof window !== "undefined" && window.location.hostname.startsWith("100.")) {
      return `http://${window.location.hostname}:25258`;
    }
    return "http://localhost:25258";
  }

  if (process.env.FUNDAMENTEI_ENV === "qa") {
    return "https://qa.fundamentei.com";
  }

  if (process.env.FUNDAMENTEI_ENV === "production") {
    return "https://fundamentei.com";
  }

  throw new Error("Couldn't figure out the base URL. Missing environment variable `FUNDAMENTEI_ENV`");
}
