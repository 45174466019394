// @ts-nocheck
/* eslint-disable */
import { ApolloLink } from "@apollo/client";
import { decode } from "lib/internal/enigma/decoder.obfuscated";
import { isPlainObject } from "lodash";

export const decodeEnigmaticResponseLink = new ApolloLink((operation, forward) => {
  return forward(operation).map((response) => {
    if (
      response &&
      isPlainObject(response) &&
      typeof response.data === "string" &&
      ((response as Record<string, any>).data as unknown as string).startsWith(")]}'")
    ) {
      const raw = (response as Record<string, any>).data as unknown as string;
      return {
        ...(response as Record<string, any>),
        data: decode(raw.substring(4)),
      };
    }
    return response;
  });
});
