/* eslint-disable no-nested-ternary */
import defaultRichTextElements from "components/intl/defaultRichTextElements";
import type { ReactNode } from "react";
import { IntlProvider } from "react-intl";

interface IntlWrapperProps {
  locale: {
    id: string;
    messages: Record<string, any>;
  };
  children: ReactNode;
}

export default function IntlWrapper({ locale, children }: IntlWrapperProps) {
  return (
    <IntlProvider
      messages={locale.messages}
      locale={locale.id}
      defaultLocale="pt-BR"
      defaultRichTextElements={defaultRichTextElements}
      onError={(error) => {
        if (error.code === "MISSING_TRANSLATION") {
          // Don't want to log anything
          return;
        }
        throw error;
      }}
    >
      {children}
    </IntlProvider>
  );
}
