/* eslint-disable no-param-reassign */

import { setContext } from "@apollo/client/link/context";
import nextTick from "lib/utils/nextTick";
import stripUndefined from "lib/utils/stripUndefined";

export default function createGitDetailsLink() {
  return setContext(async (_, { headers }) => {
    await nextTick();

    return {
      headers: {
        ...headers,
        ...stripUndefined({
          "X-Git-Sha-Long": process.env.GIT_SHA_LONG,
          "X-Git-Date": process.env.GIT_DATE,
          "X-Git-Branch": process.env.GIT_BRANCH,
        }),
      },
    };
  });
}
