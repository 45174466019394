import { decode as decodeBase64, encode as encodeBase64 } from "universal-base64";

export function arrayBufferToBase64(buffer: Uint8Array) {
  let binaryString = "";
  const bytes = new Uint8Array(buffer);

  for (let i = 0, l = bytes.byteLength; i < l; i += 1) {
    binaryString += String.fromCharCode(bytes[i]);
  }

  return encodeBase64(binaryString);
}

export function base64ToArrayBuffer(base64String: string) {
  const binaryString = decodeBase64(base64String);
  const bytes = new Uint8Array(binaryString.length);

  for (let i = 0, l = binaryString.length; i < l; i += 1) {
    bytes[i] = binaryString.charCodeAt(i);
  }

  return bytes.buffer;
}
