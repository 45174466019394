import type { LogsEvent } from "@datadog/browser-logs";
import { datadogLogs } from "@datadog/browser-logs";
import type { RumEvent } from "@datadog/browser-rum";
import { datadogRum } from "@datadog/browser-rum";
import useSelfie from "lib/hooks/useSelfie";
import type { ISelfieQuery } from "lib/queries/__generated__/SelfieQuery.generated";
import getRandomInt from "lib/utils/getRandomInt";
import { useEffect, useMemo } from "react";

export function initDataDog(selfie: ISelfieQuery["selfie"], sessionReplaySampleRate: number) {
  const didInitialize = datadogRum.getInitConfiguration() !== undefined;
  if (
    process.env.DATADOG_CLIENT_SITE &&
    process.env.DATADOG_RUM_APPLICATION_ID &&
    process.env.DATADOG_RUM_CLIENT_TOKEN &&
    !didInitialize &&
    selfie
  ) {
    datadogRum.init({
      applicationId: process.env.DATADOG_RUM_APPLICATION_ID,
      clientToken: process.env.DATADOG_RUM_CLIENT_TOKEN,
      site: process.env.DATADOG_CLIENT_SITE,

      env: process.env.FUNDAMENTEI_ENV,
      // 50% Premiums, 5% Non-Premiums
      sessionSampleRate: selfie.isPremium ? 50 : 5,
      sessionReplaySampleRate: selfie.isPremium ? sessionReplaySampleRate : 0,
      startSessionReplayRecordingManually: true,

      trackResources: true,
      trackLongTasks: true,
      trackUserInteractions: true,
      actionNameAttribute: "data-action-name",

      defaultPrivacyLevel: "mask-user-input",
      beforeSend: canSendRumEvent,

      service: "frontend",
      version: process.env.GIT_SHA_LONG,
    });
  }

  if (process.env.DATADOG_CLIENT_SITE && process.env.DATADOG_CLIENT_TOKEN) {
    datadogLogs.init({
      clientToken: process.env.DATADOG_CLIENT_TOKEN,
      site: process.env.DATADOG_CLIENT_SITE,
      forwardErrorsToLogs: true,
      sessionSampleRate: 100,
      env: process.env.FUNDAMENTEI_ENV,
      forwardConsoleLogs: ["warn", "error"],
      silentMultipleInit: true,
      // https://docs.datadoghq.com/real_user_monitoring/guide/upload-javascript-source-maps/?tab=webpackjs
      service: "frontend",
      version: process.env.GIT_SHA_LONG,
      beforeSend: canSendLogEvent,
    });
  }
}

interface DatadogInitProps {
  sessionReplaySampleRate?: number;
}
export default function DatadogInit({ sessionReplaySampleRate = 50 }: DatadogInitProps) {
  const selfie = useSelfie();
  const shouldReplaySession = useMemo(() => {
    const delta = getRandomInt(1, 100);
    return delta >= sessionReplaySampleRate;
  }, [selfie?._id]);

  useEffect(() => {
    if (selfie) {
      datadogLogs.setUser({
        id: selfie._id,
        email: selfie.email,
        name: selfie.fullName,

        // Flags
        isSiteAdmin: selfie.isSiteAdmin,
        isPremium: selfie.isPremium,
        isBlack: selfie.isBlack,
      });
      datadogRum.setUser({
        id: selfie._id,
        email: selfie.email,
        name: selfie.fullName,

        // Flags
        isSiteAdmin: selfie.isSiteAdmin,
        isPremium: selfie.isPremium,
        isBlack: selfie.isBlack,
      });

      if (selfie.isPremium && shouldReplaySession) {
        try {
          datadogRum.startSessionReplayRecording();
          // eslint-disable-next-line no-empty
        } catch {}
      }
    } else {
      datadogRum.clearUser();
      datadogLogs.clearUser();
    }

    if (!selfie || !selfie.isPremium || !shouldReplaySession) {
      try {
        datadogRum.stopSessionReplayRecording();
        // eslint-disable-next-line no-empty
      } catch {}
    }
  }, [selfie?._id]);

  useEffect(() => {
    initDataDog(selfie, sessionReplaySampleRate);
  }, []);

  return null;
}

function canSendRumEvent(event: RumEvent) {
  // Don't send events without user context
  if (!event.usr) {
    return false;
  }
  if (event.type === "error") {
    // Don't send empty messages back to DD
    if (event.error.message.trim() === "") {
      return false;
    }
    const skipAnyOf = [
      "ResizeObserver",
      "Abort fetching component for route",
      "Route did not complete loading",
      "Failed to lookup route",
      "Failed to load script",
      "Failed to fetch",
      "Network Error",
      "FedCM get() rejects",
      "api.amplitude.com",
      "algolia.net/1/indexes",
    ];
    return !skipAnyOf.some((message) => event.error.message.includes(message));
  }
  return true;
}

function canSendLogEvent(event: LogsEvent) {
  // Don't send empty messages back to DD
  if (event.message.trim() === "") {
    return false;
  }
  const skipAnyOf = [
    "ResizeObserver",
    "Abort fetching component for route",
    "Route did not complete loading",
    "Failed to lookup route",
    "Failed to load script",
    "Failed to fetch",
    "Network Error",
    "FedCM get() rejects",
    "api.amplitude.com",
    "algolia.net/1/indexes",
    "As of Tailwind CSS",
    "Update your configuration file to silence this warning",
    "XHR error GET https://customer-yupa1phl08zxqxkf.cloudflarestream.com",
  ];
  return !skipAnyOf.some((message) => {
    return event.error?.stack?.includes(message) || event.message.includes(message);
  });
}
