import gql from "graphql-tag";

import UserSettingsV2Fields from "lib/graphql/fragments/UserSettingsV2Fields";

import UserSettings from "../fragments/UserSettings";

export default gql`
  query Selfie {
    selfie {
      _id
      firstName
      lastName
      fullName
      email
      country
      isConnectedWithGoogle
      hasPasswordSet
      isPremium
      isTrialing
      isBlack
      isSiteAdmin
      isTrialing
      viewerCanPurchaseBlack
      viewerCanStartPremiumTrial
      pictureUrl
      signedUpAt
      intercom {
        appId
        verificationHash
      }
      blackSubscriptionPeriod {
        startDate
        endDate
        isSeptember23LaunchSale
      }
      ...UserSettings
      settingsV2 {
        ...UserSettingsV2Fields
      }
      featureFlags {
        flag {
          key
        }
        value
      }
    }
  }

  ${UserSettings}
  ${UserSettingsV2Fields}
`;
