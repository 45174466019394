import gql from "graphql-tag";

export default gql`
  fragment UserSettings on User {
    settings {
      bookmarkedIndicators
      assetPortfolio {
        balancingStrategy
        goalByCountry {
          americanSecurities
          brazilianSecurities
        }
        goalBySecurityClassification {
          americanCompanies
          americanReits
          brazilianCompanies
          brazilianRealEstateFunds
          americanDepositaryReceipts
          brazilianDepositaryReceipts
          brazilianExchangeTradedFunds
          americanExchangeTradedFunds
          fixedIncome
          others
        }
        allowFractions
        totalDisplay
        shouldHideFinancialValues
      }
      advertisements {
        hasClosedRemessaOnlineDiscount
        hasClosedEduardoFiisCourse
        hasClosedEduardoFirstLiveAboutFiis
        hasClosedEduardoStocksCourse
        hasClosedMyProfitPartnership
        hasClosedYearlyPlan
        hasClosedNomadPartnership
        hasClosedValuation
      }
      stream {
        appId
        appKey
        region
        userToken
      }
    }
  }
`;
